$(".header-slider").slick({
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  pauseOnFocus: false,
  arrows: false,
  cssEase: 'ease',
})

$(".circle-slider").slick({
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: true,
  fade: true,
  cssEase: 'ease',
  prevArrow: "<a href='#' class='prev link arrow invert arrow-container'></a>",
  nextArrow: "<a href='#' class='next link arrow arrow-container'></a>",
})

$(".actions-slider").slick({
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  autoplay: false,
  cssEase: 'ease',
  prevArrow: "<a href='#' class='prev link arrow invert arrow-container'></a>",
  nextArrow: "<a href='#' class='next link arrow arrow-container'></a>",
})

$(".cinema-slider").slick({
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  cssEase: 'ease',
  variableWidth: true,
  prevArrow: "<a href='#' class='prev link arrow invert arrow-container'></a>",
  nextArrow: "<a href='#' class='next link arrow arrow-container'></a>",
})

$(".gallery-slider").slick({
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  autoplay: false,
  cssEase: 'ease',
  prevArrow: "<a href='#' class='prev link arrow invert arrow-container'></a>",
  nextArrow: "<a href='#' class='next link arrow arrow-container'></a>",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
})