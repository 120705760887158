// import 'bootstrap';
import $ from 'jquery';

global.$ = global.jQuery = $;

require('lightgallery.js/dist/css/lightgallery.min.css');
require('lightgallery.js');
require("./slick.js");
require("bootstrap");
require("./carousel-configs.js");
require("./filters-opening.js");

require('../sass/page-new.scss');

// $(document).on('click', 'a:not(#smweb-panel a)', scrollAnchor);

window.addEventListener('re-render', function () {
  document.querySelectorAll('.gallery-default').forEach(gallery => lightGallery(gallery));
});

window.lightGallery(document.getElementsByClassName('lightgallery-container')[0], {
  selector: '.item',
});

$(window).scroll(function () {
  // console.log('Scrolling ...');
});

$(document).ready(function () {
  window.dispatchEvent(new CustomEvent('re-render'));
});

window.addEventListener("load", () => {
  const fullClickable = [
    ".actions-slider .actions-slider__slide",
    ".cinema-slider .cinema-slider__slide",
    ".library .card",
    ".community .card",
    ".activities-list .activity",
  ]
  fullClickable.forEach(selector => {
    document.querySelectorAll(selector)?.forEach(item => {
      item.style.cursor = "pointer";
      item.addEventListener("click", () => {
        if (item.querySelector(".cke_editable")) return;
        if (item.classList.contains("cke_editable")) return;
        window.location.href = item.querySelector(".link").href;
      });
    });
  });
});

const isImageDark = (image) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = 20;
  canvas.height = 20;
  context.drawImage(image, 0, 0, 20, 20);
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  let brightnessSum = 0;
  let pixelCount = 0;

  for (let i = 0; i < data.length; i += 4) {
    const r = data[i];
    const g = data[i + 1];
    const b = data[i + 2];
    const brightness = (0.299 * r + 0.587 * g + 0.114 * b);
    brightnessSum += brightness;
    pixelCount++;
  }
  const averageBrightness = brightnessSum / pixelCount;
  return averageBrightness < 128 ? "dark" : "light";
}

window.addEventListener('load', function () {
  const slides = document.querySelectorAll('.header-slider .header-slider__slide ');
  slides.forEach(slide => {
    const image = slide.querySelector('img');
    const bg = isImageDark(image);
    slide.classList.add(bg);
  })
});
